import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location: { pathname } }) => (
  <Layout>
      <SEO title="404: Not found" pathname={pathname} />
    <div style={{ padding: '2rem' }}>
      <h1>NOT FOUND</h1>
      <h3>Blixt, brak och dunder! Bomber och granater och krevader!</h3>
      <p>
        Vid alla nerlusade sopprötter, något har gått åt helvetet.<br />
      </p>
      <em>Detta fel uppstod förmodligen på grund av att jag glömde prillan hemma</em>
    </div>
  </Layout>
)

export default NotFoundPage
